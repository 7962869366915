import { Program } from './Program';
import { GenerateKoboURLData } from '@wsb_dev/datafi-shared/lib/types/ActionTaskTypes';
import { ActionScope, DefaultActionData } from '@wsb_dev/datafi-shared/lib/types/ActionTypes';
import { ValidatedUser } from './Users';
import { LogManager } from 'aurelia-framework';
import { ProgramSurvey as BaseProgramSurvey } from '@wsb_dev/datafi-shared/lib/types/ProgramSurvey';
import { programHasUserPermission } from '@wsb_dev/datafi-shared/lib/util/programs/programHasUserPermission';
import { Field } from '@wsb_dev/datafi-shared/lib/types';

const log = LogManager.getLogger('dfp:program-survey');

function getDefaultActions(props: Partial<BaseProgramSurvey>): DefaultActionData[] {
    if (!props.id) {
        return [];
    }
    return [
        {
            icon: 'edit',
            label: 'Edit',
            id: 'GenerateKoboURL',
            surveyId: props.id,
            inline: true,
            edit: true,
            open: true,
        } as GenerateKoboURLData,
        {
            icon: 'library_add',
            label: 'Add',
            id: 'GenerateKoboURL',
            surveyId: props.id,
            scopes: [
                'program-surveys',
                'project-surveys',
            ],
            open: true,
        } as GenerateKoboURLData,
    ] as unknown as DefaultActionData[];
}

export interface ProgramSurvey extends BaseProgramSurvey { }
export class ProgramSurvey {
    constructor(data: Partial<ProgramSurvey>) {
        if (!data.actions?.length) {
            data.actions = getDefaultActions(data);
        }

        if (!data.surveySchema) {
            data.surveySchema = [];
        }
        Object.assign(this, data);
    }

    getActions(user: ValidatedUser, program: Program, ...scopes: ActionScope[] | ActionScope[][]): DefaultActionData[] {
        const _scopes: ActionScope[] = Array.isArray(scopes[0]) ?
            scopes[0] :
            scopes as ActionScope[];

        return this.actions?.filter((a) => {

            // if action is set as disabled then we always return false
            if (a.disabled) {
                return false;
            }

            return this.isValidForUser(a, user, program) &&
                this.isValidForScope(a, _scopes);
        });
    }

    isValidForScope(action: DefaultActionData, scopes: ActionScope[]) {
        if (!action.scopes?.length) {
            return true;
        }

        for (const scope of action.scopes) {
            if (scopes.indexOf(scope) > -1) {
                return true;
            }
        }
        log.debug('ProgramSurvey: action hidden: invalid scope', action);
        return false;
    }

    isValidForUser(action: DefaultActionData, user?: ValidatedUser, program?: Program) {

        // LEGACY
        if (action.roles?.length) {
            log.warn('action.roles is deprecated', action);
            return program.hasUserRole(user, action.roles);
        }

        // if no roles required we always return true
        if (!action.permission) {
            return true;
        }

        if (!user) {
            log.debug('ProgramSurvey: action invalid: no user', action);
            return false;
        }

        const { permission, service } = action.permission;

        if (programHasUserPermission(program, user, service, permission)) {
            return true;
        }

        log.debug('ProgramSurvey: action hidden: invalid role', action);
        return false;
    }

}

export const surveyFields: Field[] = [
    { name: 'id', type: 'number', visibility: { list: false, filter: false } },
    { name: 'project_id', type: 'number', visibility: { list: false, filter: false } },
    { name: 'program_id', type: 'number', visibility: { list: false, filter: false } },
    { name: 'survey_id', type: 'number', visibility: { list: false, filter: false } },
    { name: 'createdAt', label: 'Date Created', type: 'date', visibility: { list: true, filter: true } },
    { name: 'updatedAt', label: 'Date Updated', type: 'date', visibility: { list: true, filter: true } },
    { name: 'createdBy', label: 'Created By', type: 'string', visibility: { list: true, filter: true } },
    { name: 'updatedBy', label: 'Updated By', type: 'string', visibility: { list: true, filter: true } },
    { name: 'geom', type: 'text', visibility: { list: false, filter: false } },
];
